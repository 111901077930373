export const useNotification = () => {
    const $q = useQuasar()

    function notify(type: "positive" | "negative", msg: string) {
        $q.notify({
            type: type,
            message: msg,
            position: "top-right",
        })
    }

    return notify
}
